<template>
  <div
    class="dropdown"
    :class="[{ 'is-active': isActive }, { 'is-right': isRight }]"
    v-click-outside="close"
  >
    <div class="dropdown-trigger">
      <button
        class="button is-fullwidth is-justify-content-space-between"
        @click="isActive = !isActive"
      >
        <span class="is-active has-text-weight-normal">
          {{ selected || placeholder }}
        </span>
        <span class="icon is-small">
          <i class="fal fa-angle-down" />
        </span>
      </button>
    </div>
    <div 
      class="dropdown-menu" 
      role="menu"
    >
      <div class="dropdown-content">
        <a
          class="dropdown-item py-3"
          :class="{ selected: option === selected }"
          v-for="(option, index) in options"
          :key="index"
          @click.prevent="toggle(option)"
        >
          <span>{{ option }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownSelect',
  data: () => ({
    isActive: false
  }),
  props: {
    options: {
      type: Array,
      required: true
    },
    selected: {
      type: [Number, String],
      required: false,
      default: () => {
        return this.placeholder
      }
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    isRight: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    toggle(option) {
      this.isActive = false
      this.$emit('toggle', option)
    },
    close() {
      this.isActive = false
    }
  }
}
</script>

<style lang="sass" scoped>
.dropdown,
.dropdown-trigger
  width: 100%
.dropdown-menu
  min-width: 100%
  display: none
  max-height: 30em
.dropdown-content
  overflow-y: scroll
  max-height: inherit
.button:focus
  border-color: rgba(32, 30, 31, 0.29)
.dropdown-item:hover
  background: $primary
  color: white
.selected
  background: $white-ter
  &:hover
    background: $white-ter
    cursor: not-allowed
  span, span:hover
    color: $grey-light-dark
</style>
